import { actionsBank } from '../actions/actionBank';
import indexedDBHelper from '../helpers/indexedDBHelper';
import { isMobile } from '../../services/device/deviceData';
import { handlePixelData } from '../../services/gtmHelper/gtmHelper.ts'

import { getHeight, shouldHidePoweredBy } from '../../services/layoutCalculations/layoutCalculations';
// import {queryString}
import { urlParams } from '../../services/queryString/queryString';
const instanceId = urlParams.instanceId;
const postMessageMiddleware = store => next => async action => {
  switch (action.type) {
    case actionsBank.ASYNC_STATE_READY: {
      const { paddingTop, paddingBottom, items, headerText, bottomBorderWidth, topBorderWidth } = store.getState().strip;
      const hidePoweredBy = shouldHidePoweredBy(items);
      const headerTextExists = Boolean(headerText);
      const stripHeight = getHeight(isMobile, headerTextExists, hidePoweredBy, paddingTop, paddingBottom, bottomBorderWidth, topBorderWidth);
      window.parent.postMessage({ type: 'strip_loaded', instanceId, mobileStripHeight: stripHeight }, '*');
      break;
    }
    case actionsBank.ITEM_CLICKED: {
      window.parent.postMessage({ type: 'strip_open_unit', interactionIndex: action.payload.interactionIndex, instanceId, interactionId: action.payload.interactionId }, '*');
      indexedDBHelper.setInteractionSeen(action.payload.interactionId);
      break;
    }
    case actionsBank.TOUCH_START: {
      window.parent.postMessage({ type: 'strip_touch_start', instanceId }, '*');
      break;
    }
    case actionsBank.TOUCH_END: {
      window.parent.postMessage({ type: 'strip_touch_end', instanceId }, '*');
      break;
    }
    case actionsBank.STRIP_ITEMS_FETCHED: {
      const stripItems = action.payload.stripItems || [];
      if (stripItems && stripItems.length) {
        window.parent.postMessage({ type: 'strip_item_loaded', instanceId, firstInteraction: stripItems[0].interactionId, stripItems, payload: action.payload }, '*');
        const trackingPixelEnable = (stripItems[0].publisher && stripItems[0].publisher.trackingPixel && stripItems[0].publisher.trackingPixel.active) || false;
        handlePixelData(trackingPixelEnable);
      } else {
        //TODO: close strip
        window.parent.postMessage({ type: 'close_strip', reason: 'insufficent unit number', instanceId }, '*');
      }
      break;
    }
    case actionsBank.CLOSE_STRIP: {
      const { reason } = action.payload;
      window.parent.postMessage({ type: 'close_strip', reason, instanceId }, '*');
      break;
    }
    default: {
    }
  }
  const result = next(action);
  return result;
};

export default postMessageMiddleware;
