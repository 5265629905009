import React from 'react';

const ImageWrapper = ({ avatarStyle, children }) => (
  <>
    {children}
    <div className="overlay-position" style={avatarStyle} />
  </>
);

export default ImageWrapper;
