import config from '../../config/config';

export async function handlePixelData(shouldAppendGtm) {
  
  if (shouldAppendGtm) {
    const gtmTags = generateGtmTags();
    document.head.appendChild(gtmTags.gtmHeaderScript);
    document.head.appendChild(gtmTags.gtmLink);
    document.body.appendChild(gtmTags.gtmBodyScript);
  }
}

function generateGtmTags() {
  const gtmHeaderScript = document.createElement('script');
  gtmHeaderScript.async = true;
  gtmHeaderScript.innerHTML = "(function(w, d, s, l, i) {w[l] = w[l] || [];w[l].push({'gtm.start': new Date().getTime(),event: 'gtm.js'});var f = d.getElementsByTagName(s)[0],j = d.createElement(s),dl = l != 'dataLayer' ? '&l=' + l : '';j.async = true;j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;f.parentNode.insertBefore(j, f);})(window, document, 'script', 'dataLayer', 'GTM-KVVTMD');"
  
  const gtmLink = document.createElement('link');
  gtmLink.rel = "preconnect";
  gtmLink.href = "https://www.googletagmanager.com";
  gtmLink.crossOrigin = 'true';

  const gtmIframe = document.createElement('iframe');
  gtmIframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-KVVTMD";
  gtmIframe.height = '0';
  gtmIframe.width = '0';
  gtmIframe.style.display = 'none';
  gtmIframe.style.visibility = 'hidden';

  const gtmBodyScript = document.createElement('noscript').appendChild(gtmIframe);
  
  let gtmTags = {gtmHeaderScript: gtmHeaderScript, gtmLink: gtmLink, gtmBodyScript: gtmBodyScript};
  return gtmTags;
}