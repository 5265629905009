import React from 'react';
import config from '../../config/config';
import apesterLogo from '../../assets/images/apester-logo-shape.svg';
import './header.css';
import { SIZE_OF_POWERED_BY, SIZE_OF_POWERED_BY_MOBILE, DEFAULT_HEADER_LINE_HEIGHT } from './constants';
let FontFaceObserver = require('fontfaceobserver');

class Header extends React.Component {
  componentWillMount() {
    this.renderHeaderfont();
  }

  renderHeaderfont() {
    const { headerText, headerStyle } = this.props;
    if (headerText && headerStyle && headerStyle.fontFamily !== 'Lato') {
      this.loadFontCss(headerStyle.fontFamily, headerStyle.provider);
    }

    const font = new FontFaceObserver(headerStyle.fontFamily, { weight: headerStyle.fontWeight });

    font.load().then(() => {
      this.props.resizeStripMessage();
    }).catch(() => {
      this.props.resizeStripMessage();
    });;
  }

  loadFontCss(fontFamily, provider) {
    const head = document.head;
    const link = document.createElement('link');

    link.rel = 'stylesheet';
    link.type = 'text/css';

    if (provider === 'system') {
      link.href = `${config.FONTS_URL}/fonts/fonts.${fontFamily}.css`;
    } else {
      link.href = `https://fonts.googleapis.com/css?family=${fontFamily}`;
    }

    if (head) {
      head.appendChild(link);
    }
  }

  renderPoweredBy() {
    const { hidePoweredBy, headerStyle, headerText, isMobile } = this.props;
    const sizeOfPoweredBy = isMobile ? SIZE_OF_POWERED_BY : SIZE_OF_POWERED_BY_MOBILE;
    const poweredByPaddingBottom = headerText ? (parseInt(headerStyle.fontSize.replace('px', '')) - sizeOfPoweredBy) : 0;

    if (!hidePoweredBy) {
      return (
        <div className="ic powered-by" style={{ paddingBottom: poweredByPaddingBottom }}>
          <p>Powered by</p>
          <a href="https://landing.apester.com/ots" target="_blank" rel="noopener noreferrer">
            <img alt="logo" className="image-logo" src={apesterLogo} />
          </a>
        </div>
      );
    }
  }

  render() {
    const { headerText, headerStyle, headerLtr, hidePoweredBy, isMobile, isPreview } = this.props;
    if(isPreview){
      this.renderHeaderfont();
    }
    const headerFlexStyle = {
      flexDirection: headerLtr ? 'row' : 'row-reverse',
      display: hidePoweredBy && !headerText ? 'none' : '',
      paddingTop: isPreview ? '5px' : '10px' 
    };
    const headerLtrStyle = {
      direction: headerLtr ? 'ltr' : 'rtl',
    };
    let headerTextStyle = { ...headerStyle, ...headerLtrStyle };
    const maxHeightPoweredBy = isMobile ? SIZE_OF_POWERED_BY_MOBILE : SIZE_OF_POWERED_BY;
    const maxHeaderHeight = Math.max(parseInt(headerTextStyle.fontSize) * DEFAULT_HEADER_LINE_HEIGHT, maxHeightPoweredBy);
    headerFlexStyle.maxHeight = `${maxHeaderHeight}px`;
    return (
      <div style={headerFlexStyle} className={`strip-header${isMobile ? ' header-mobile' : ''}`}>
        {headerText ? (
          <div style={headerTextStyle} className="header-title">
            {headerText}
          </div>
        ) : (
          <div className="header-title-skeleton"></div>
        )}
        {this.renderPoweredBy()}
      </div>
    );
  }
}

export default Header;
