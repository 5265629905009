const defaultConfig = {
  YOUTUBE_IMAGE_URL: 'https://img.youtube.com/vi/',
  YOUTUBE_IMAGE_DEFAULT_SIZE: 'hqdefault.jpg',
  IMAGE_PROXY_URL: 'https://img.apester.com',
  STATIC_BUCKET: 'https://static.apester.com',
  SHARE_SERVICE_URL: 'https://ape.st/share',
  YOUTUBE_IFRAME_API: '//www.youtube.com/iframe_api',
  FONTS_URL: 'https://static.apester.com'
};

const clientConfig = {
  IMAGE_PROXY_URL: process.env.REACT_APP_IMAGE_PROXY_URL,
  STATIC_BUCKET: process.env.REACT_APP_STATIC_BUCKET,
  DISPLAY_BASE_URL: process.env.REACT_APP_DISPLAY_BASE_URL,
  USERS_BASE_URL: process.env.REACT_APP_USERS_BASE_URL,
  FONTS_URL: process.env.REACT_APP_FONTS_BASE_URL,
  RELEASE: process.env.REACT_APP_RELEASE,
};

const config = { ...defaultConfig, ...clientConfig };
// @ts-ignore
window.clientConfig = config;
export default config;
