const url = new URL((window.location as unknown) as string);
const urlSearchParams = new URLSearchParams(url.search);
export const urlParams = Array.from(urlSearchParams.entries()).reduce(
  (acc, [key, value]) => {
    acc[key] = value;
    return acc;
  },
  {} as UrlParams,
);

interface UrlParams {
  [key: string]: string;
}

export default urlParams;
