import { actionsBank } from '../actions/actionBank';
import indexedDBHelper from "../helpers/indexedDBHelper";

const getAsyncStateProps = async () => {
  const seen = await indexedDBHelper.getAllSeenInteractions();
  return { seen };
};


const asyncStatePropsMiddleware = store => next => async action => {
  switch (action.type) {
    case (actionsBank.INIT_ASYNC_STATE_PROPS): {
      const asyncStateProps = await getAsyncStateProps();
      return next({ type: actionsBank.ASYNC_STATE_READY, payload: { asyncStateProps } });
    }
    case (actionsBank.CHANGE_CURRENT): {
      indexedDBHelper.setInteractionSeen(action.payload.interactionId);
      return next(action);
    }
    default:
      return next(action);
  }
};

export default asyncStatePropsMiddleware;
