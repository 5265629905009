import { openDB } from 'idb';

const DB_NAME = 'APESTER';
const DB_VERSION = 1;
const STORE_NAME = 'STRIP';
let dbPromise = Promise.resolve();
try {
  dbPromise = openDB(DB_NAME, DB_VERSION, {
    upgrade: db => {
      db.createObjectStore(STORE_NAME);
    },
  });
} catch (e) {
  console.log('Issue with indexdb');
}

class IndexedDBHelper {
  static async getAllSeenInteractions() {
    try {
      const store = (await dbPromise).transaction(STORE_NAME).objectStore(STORE_NAME);
      return await store.getAllKeys().then(allInteractions => {
        // The object is formated like this {1: interactionId, 2: interactionId}
        // and we want it to be {interactionId: 1, interactionId: 2}
        return allInteractions.reduce((acc, interactionId) => {
          acc[interactionId] = true;
          return acc;
        }, {});
      });
    } catch (e) {}
  }

  static async setInteractionSeen(interactionId) {
    try {
      const tx = (await dbPromise).transaction(STORE_NAME, 'readwrite');
      const store = tx.objectStore(STORE_NAME);
      const isInteractionSeen = await store.get(interactionId);
      if (!isInteractionSeen) {
        await store.put(true, interactionId);
      }
      return tx.done;
    } catch (e) {}
  }
}

export default IndexedDBHelper;
