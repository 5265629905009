const styleMap = {
  desktop: {
    square: {
      small: {
        width: '116px',
        height: '166px',
        thumbnailTotalWidth: '124px',
        thumbnailTotalHeight: '174px',
        spaceBetween: 12,
        textBottomPosition: '12',
        borderRadius: '1px',
        fontSize: 14,
        textWidth: '96px',
      },
      medium: {
        width: '134px',
        height: '202px',
        thumbnailTotalWidth: '142px',
        thumbnailTotalHeight: '210px',
        spaceBetween: 14,
        textBottomPosition: '14',
        textWidth: '114px',
        borderRadius: '1px',
        fontSize: 16,
        whiteSpace: 'normal',
      },
      large: {
        width: '178px',
        height: '258px',
        thumbnailTotalWidth: '186px',
        thumbnailTotalHeight: '266px',
        spaceBetween: 16,
        textWidth: '154px',
        textBottomPosition: '18',
        borderRadius: '1px',
        fontSize: 18,
        whiteSpace: 'normal',
      },
    },
    round: {
      small: {
        width: '104px',
        height: '104px',
        thumbnailTotalWidth: '112px',
        thumbnailTotalHeight: '112px',
        spaceBetween: 12,
        textBottomPosition: '-10',
        borderRadius: '50%',
        fontSize: 14,
        textWidth: '112px',
      },
      medium: {
        width: '134px',
        height: '134px',
        thumbnailTotalWidth: '142px',
        thumbnailTotalHeight: '142px',
        spaceBetween: 16,
        textBottomPosition: '-12',
        borderRadius: '50%',
        fontSize: 16,
        textWidth: '146px',
        whiteSpace: 'normal',
      },
      large: {
        width: '170px',
        height: '170px',
        thumbnailTotalWidth: '178px',
        thumbnailTotalHeight: '178px',
        spaceBetween: 16,
        textBottomPosition: '-12',
        borderRadius: '50%',
        fontSize: 18,
        textWidth: '182px',
        whiteSpace: 'normal',
      },
    },
    roundSquare: {
      small: {
        width: '116px',
        height: '166px',
        thumbnailTotalWidth: '124px',
        thumbnailTotalHeight: '174px',
        spaceBetween: 12,
        textBottomPosition: '12',
        textWidth: '96px',
        borderRadius: '8px',
        fontSize: 14,
      },
      medium: {
        width: '134px',
        height: '202px',
        thumbnailTotalWidth: '142px',
        thumbnailTotalHeight: '210px',
        spaceBetween: 14,
        textBottomPosition: '14',
        textWidth: '114px',
        borderRadius: '8px',
        fontSize: 16,
        whiteSpace: 'normal',
      },
      large: {
        width: '178px',
        height: '258px',
        thumbnailTotalWidth: '186px',
        thumbnailTotalHeight: '266px',
        spaceBetween: 14,
        textBottomPosition: '18',
        textWidth: '154px',
        borderRadius: '8px',
        fontSize: 18,
        whiteSpace: 'normal',
      },
    },
  },
  mobile: {
    square: {
      small: {
        width: '64px',
        height: '94px',
        thumbnailTotalWidth: '72px',
        thumbnailTotalHeight: '102px',
        spaceBetween: 10,
        textBottomPosition: '-8',
        borderRadius: '1px',
        fontSize: 12,
        textWidth: '70px',
        whiteSpace: 'nowrap',
      },
      medium: {
        width: '106px',
        height: '160px',
        thumbnailTotalWidth: '114px',
        thumbnailTotalHeight: '168px',
        spaceBetween: 12,
        textBottomPosition: '14',
        whiteSpace: 'normal',
        overFlow: 'true',
        borderRadius: '1px',
        textWidth: '86px',
        fontSize: 12,
      },
      large: {
        width: '124px',
        height: '184px',
        thumbnailTotalWidth: '132px',
        thumbnailTotalHeight: '192px',
        spaceBetween: 14,
        textBottomPosition: '14',
        whiteSpace: 'normal',
        overFlow: 'true',
        borderRadius: '1px',
        textWidth: '110px',
        fontSize: 14,
      },
    },
    round: {
      small: {
        width: '65px',
        height: '65px',
        thumbnailTotalWidth: '70px',
        thumbnailTotalHeight: '70px',
        spaceBetween: 16,
        textBottomPosition: '-6',
        borderRadius: '50%',
        fontSize: 12,
        whiteSpace: 'nowrap',
        textWidth: '70px',
        textMarginTop: '8px',
      },
      medium: {
        width: '86px',
        height: '86px',
        thumbnailTotalWidth: '92px',
        thumbnailTotalHeight: '92px',
        spaceBetween: 16,
        textBottomPosition: '-6',
        borderRadius: '50%',
        fontSize: 12,
        whiteSpace: 'normal',
        textWidth: '92px',
        textMarginTop: '10px',
      },
      large: {
        width: '114px',
        height: '114px',
        thumbnailTotalWidth: '120px',
        thumbnailTotalHeight: '120px',
        spaceBetween: 16,
        textBottomPosition: '-6',
        overFlow: 'true',
        borderRadius: '50%',
        fontSize: 14,
        whiteSpace: 'normal',
        textWidth: '120px',
      },
    },
    roundSquare: {
      small: {
        width: '64px',
        height: '94px',
        thumbnailTotalWidth: '72px',
        thumbnailTotalHeight: '102px',
        spaceBetween: 10,
        textBottomPosition: '-8',
        borderRadius: '8px',
        fontSize: 12,
        textWidth: '70px',
        whiteSpace: 'nowrap',
      },
      medium: {
        width: '106px',
        height: '160px',
        thumbnailTotalWidth: '114px',
        thumbnailTotalHeight: '168px',
        spaceBetween: 12,
        textBottomPosition: '14',
        overFlow: 'true',
        borderRadius: '8px',
        fontSize: 12,
        textWidth: '86px',
        whiteSpace: 'normal',
      },
      large: {
        width: '124px',
        height: '184px',
        thumbnailTotalWidth: '132px',
        thumbnailTotalHeight: '192px',
        spaceBetween: 14,
        textBottomPosition: '14',
        overFlow: 'true',
        borderRadius: '8px',
        fontSize: 14,
        textWidth: '110px',
        whiteSpace: 'normal',
      },
    },
  },
};

const getStripStyle = (device, shape, size) => {
  if (styleMap[device][shape]) {
    return styleMap[device][shape][size] || styleMap[device][shape].medium;
  }
  return styleMap[device].square[size] || styleMap[device].square.medium;
};

export default getStripStyle;
