import * as React from 'react';
import ReactDOM from 'react-dom';
// @ts-ignore
import * as apesterImage from '@apester/image';
import App from './components/App';
import config from './config/config';
import * as serviceWorker from './serviceWorker';

apesterImage.init(config.IMAGE_PROXY_URL, config.STATIC_BUCKET);

ReactDOM.render(<App />, document.getElementById('root'));
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
