import React from 'react';
import { imageAdapter, Image } from '@apester/image';
import ImageRingWrapper from './ImageWrappers/ImageRingWrapper';
import getStripStyle from '../StripContainer/strip-style-map';
import ImageWrapper from './ImageWrappers/ImageWrapper'

const borderRadiusMap = new Map([['1px', 'image-square'], ['8px', 'image-square-round'], ['50%', 'image-round']]);

const ItemImage = ({ itemSize, itemShape, isMobile, image, avatarStyle, hasSeen, isLoading, thumbnailsStrokeColor }) => {
  const platform = isMobile ? 'mobile' : 'desktop';
  const isMobileSmallRound = isMobile && itemShape === 'round' && itemSize === 'small';
  const {width, height, thumbnailTotalWidth, thumbnailTotalHeight} = getStripStyle(platform, itemShape, itemSize);
  
  return (
    <ImageWrapper avatarStyle={avatarStyle}>
      <ImageRingWrapper 
        isMobileSmallRound={isMobileSmallRound} thumbnailTotalWidth={thumbnailTotalWidth} thumbnailTotalHeight={thumbnailTotalHeight} 
        hasSeen={hasSeen} thumbnailsStrokeColor={thumbnailsStrokeColor} isLoading={isLoading} shape={itemShape}
      >
        <div style={{ width: width, height: height, position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', overflow: 'hidden'}}
              className={`${borderRadiusMap.get(avatarStyle.borderRadius)}`}>
          {image ? (
            <Image className={`image-item-new ${borderRadiusMap.get(avatarStyle.borderRadius)}`} shouldAnimateBackground={false} imageElement={imageAdapter(image)} />
          ) : (
            <div className={`image-item-new ${borderRadiusMap.get(avatarStyle.borderRadius)}`} />
          )}
        </div>
      </ImageRingWrapper>
    </ImageWrapper>
  );
};

export default ItemImage;
