export const actionsBank = {
  INIT_ASYNC_STATE_PROPS: 'INIT_ASYNC_STATE_PROPS',
  ITEM_CLICKED: 'ITEM_CLICKED',
  CHANGE_CURRENT: 'CHANGE_CURRENT',
  ASYNC_STATE_READY: 'ASYNC_STATE_READY',
  STRIP_SEEN: 'STRIP_SEEN',
  TOUCH_START: 'TOUCH_START',
  TOUCH_END: 'TOUCH_END',
  STRIP_ITEMS_FETCHED: 'STRIP_ITEMS_FETCHED',
  CLOSE_STRIP: 'CLOSE_STRIP',
  STRIP_ATTR_CHANGE: 'STRIP_ATTR_CHANGE',
  STRIP_REFRESH_CONTENT: 'apester_strip_refresh_content',
};

export const GTM_ACTIONS = {
  STRIP_LOADED: 'STRIP_LOADED',
  STRIP_UNIT_CLICKED: 'STRIP_UNIT_CLICKED',
  STRIP_SEEN: 'STRIP_SEEN',
};
