import React from 'react';
import { connect } from 'react-redux';
import GlidderWrapper from '../GliderWrapper/GliderWrapper';
import StripItem from '../StripItem/stripItem';
import Header from '../Header/Header';
import { createOnviewObserver} from '../../services/ViewabilityService';
import { isMobile as isMobileByDevice } from '../../services/device/deviceData';
import { getHeight, shouldHidePoweredBy } from '../../services/layoutCalculations/layoutCalculations';
import interactionDataExtractor from './interactionDataExtractor';
import { itemClicked, changeCurrent, initAsyncStateProps, stripSeen, stripTouchStart, stripTouchEnd, stripAttrChange } from '../../store/actions/actions';
import './strip-container.css';
import { DEFAULT_HEADER_LINE_HEIGHT } from '../Header/constants';
import getStripStyle from './strip-style-map';

const getElementPerView = (width, spaceBetween) => {
  const swiperElementWidth = document.getElementById('root') && document.getElementById('root').clientWidth;
  return swiperElementWidth / (parseInt(width) + spaceBetween);
};
export class StripContainer extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    this.resizeStripMessage();

    createOnviewObserver(this.ref.current, this.props.stripSeen);

    // Execute when a new interaction displays
    window.addEventListener(
      'message',
      event => {
        if (event.data.type === 'strip_next_unit') {
          const { index } = event.data;
          this.props.changeCurrent(index, this.props.items[index].interactionId);
        }
        if (event.data.type === 'strip_attr') {
          //in preview mode don't change strip background
          if (this.props.isPreview) {
            event.data.attr.stripBackground = 'transparent';
          }
          if (event.data.attr.itemHasShadow) {
            let itemHasShadowToString = event.data.attr.itemHasShadow.toString();
            event.data.attr.itemHasShadow = itemHasShadowToString;
          }
          this.props.stripAttrChange(event.data.attr);
        }
      },
      false,
    );
  }

  componentDidUpdate(prevProps, prevState) {
    // In comment - user experience issue
    // Update strip slide according to current index
    if (prevProps.current !== this.props.current) {
      this.updateCurrentStripIndex(this.props.current);
    }
    if(this.props.isPreview){
      this.resizeStripMessage();
    }
  }

  updateCurrentStripIndex(index) {
    if (window.Glider) {
      const glider = window.Glider(document.querySelector('.glider'));
      glider.scrollItem(index);
    }
  }

  resizeStrip = () => {
    const { items, shouldSimulateMobile, headerText, paddingTop, paddingBottom, bottomBorderWidth, topBorderWidth } = this.props;
    const headerTextExists = Boolean(headerText);
    const isMobile = Boolean(shouldSimulateMobile || isMobileByDevice);
    const hidePoweredBy = shouldHidePoweredBy(items);
    const stripHeight = getHeight(isMobile, headerTextExists, hidePoweredBy, paddingTop, paddingBottom, bottomBorderWidth, topBorderWidth);
    //if header text exist - calculate header based on it's height
    if (headerText) {
      let headerHeight =
        (document.getElementsByClassName('strip-header') && document.getElementsByClassName('strip-header')[0] && document.getElementsByClassName('strip-header')[0].offsetHeight) || 0;

      //fix for safari mobile - if header wasn't rendered
      if (headerHeight === 0) {
        setTimeout(this.resizeStripMessage, 1000);
      }
    }

    return stripHeight;
  };

  resizeStripMessage = () => {
    window.parent.postMessage({ type: 'RESIZE_STRIP', height: this.resizeStrip(), token: this.props.token }, '*');
  };

  renderHeader() {
    const { headerProvider, headerText, headerFontFamily, headerFontWeight, headerFontColor, headerFontSize, headerLtr, horizontalHeaderPadding, items, isPreview, shouldSimulateMobile } = this.props;
    const hidePoweredBy = shouldHidePoweredBy(items);
    const isMobile = Boolean(shouldSimulateMobile || isMobileByDevice);
    const headerLtrBool = headerLtr === 'true';

    const headerStyle = {
      [headerLtrBool ? 'paddingLeft' : 'paddingRight']: `${horizontalHeaderPadding}px`,
      fontFamily: headerFontFamily,
      fontWeight: headerFontWeight,
      color: headerFontColor,
      fontSize: `${headerFontSize}px`,
      lineHeight: DEFAULT_HEADER_LINE_HEIGHT,
      provider: headerProvider
    };
    return (
      <Header
        headerText={headerText}
        headerStyle={headerStyle}
        headerLtr={headerLtr.toString() === 'true'}
        hidePoweredBy={hidePoweredBy}
        resizeStripMessage={this.resizeStripMessage}
        isMobile={isMobile}
        isPreview={isPreview}
      />
    );
  }

  renderItems(customStyle, items) {
    const { extractImageElement, extractCaption } = interactionDataExtractor;
    const { seen, onItemClicked, currentlyLoadedIndex, stripTouchStart, stripTouchEnd, itemShape, itemSize, shouldSimulateMobile } = this.props;
    let isMobile = customStyle.isMobile || shouldSimulateMobile;

    const itemStyle = {
      width: customStyle.thumbnailTotalWidth,
      marginRight: customStyle.spaceBetween + 'px',
    };
    
        return items.map((item, idx) => (
      <div style={itemStyle} key={item.interactionId === 'no-interaction' ? idx : item.interactionId} onPointerUp={() => stripTouchEnd()} onPointerDown={() => stripTouchStart()}>
        <StripItem
          itemSize={itemSize}
          itemShape={itemShape}
          itemStyle={customStyle}
          key={item.interactionId === 'no-interaction' ? idx : item.interactionId}
          interactionId={item.interactionId}
          index={idx}
          hasSeen={Boolean(seen && seen[item.interactionId])}
          isLoading={currentlyLoadedIndex === idx}
          image={item.interactionId === 'no-interaction' ? undefined : extractImageElement(item)}
          caption={item.interactionId === 'no-interaction' ? undefined : extractCaption(item)}
          isMobile={isMobile}
          onClick={e => {
            onItemClicked(idx, item.interactionId);
          }}
        />
      </div>
    ));
  }

  render() {
    const {
      items,
      itemShape,
      itemSize,
      stripBackground,
      itemTextColor,
      isPreview,
      shouldSimulateMobile,
      paddingTop = 0,
      paddingBottom = 0,
      bottomBorderWidth,
      bottomBorderColor,
      topBorderWidth,
      topBorderColor,
      thumbnailsStrokeColor
    } = this.props;

    const itemsToRender = items && items.length ? items : new Array(11).fill('').map(e => ({ interactionId: 'no-interaction' }));
    const isMobile = Boolean(shouldSimulateMobile || isMobileByDevice);
    
    if (isPreview) {
      this.resizeStripMessage();
    }
    const device = isMobile ? 'mobile' : 'desktop';

    const customStyle = itemShape && {
      ...getStripStyle(device, itemShape, itemSize),
      itemTextColor,
      isMobile,
      isPreview,
      thumbnailsStrokeColor
    };
    const containerStyle = { background: stripBackground, borderTop: `${topBorderWidth}px solid ${topBorderColor}`, borderBottom: `${bottomBorderWidth}px solid ${bottomBorderColor}` };
    const { spaceBetween, thumbnailTotalWidth } = customStyle;
    const elementsPerView = getElementPerView(thumbnailTotalWidth, spaceBetween);
    const itemWidth = Number(customStyle.spaceBetween) + Number(thumbnailTotalWidth.slice(0, -2))

    return (
      <React.Fragment>
        <style
          dangerouslySetInnerHTML={{
            __html: `
                    .glider-track {padding-top: 14px; padding-bottom: 12px}
                    .glider-slide {margin-right: ${customStyle.spaceBetween + 'px' || '20px'} !important;}
                    .strip-element {padding-top: ${paddingTop}px}; padding-bottom: ${paddingBottom}px;`,
          }}
        />
        <div style={containerStyle} className={`strip-element ${isMobile ? 'strip-element-mobile' : 'strip-element-desktop'} ${isPreview ? 'preview' : ''} `} ref={this.ref}>
          {this.renderHeader(itemSize)}
          <GlidderWrapper
            resizeLock={false}
            draggable={true}
            dragVelocity={1}
            duration={1.5}
            slidesToShow={elementsPerView}
            slidesToScroll={2}
            isDesktop={!isMobile}
            exactWidth={true}
            itemWidth={itemWidth}
            itemShape={itemShape}
            resizeStripMessage={this.resizeStripMessage}
          >
            {this.renderItems(customStyle, itemsToRender)}
          </GlidderWrapper>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ strip }) => ({
  current: strip.current,
  items: strip.items,
  seen: strip.seen,
  currentlyLoadedIndex: strip.currentlyLoadedIndex,
  itemTextColor: strip.itemTextColor,
  token: strip.token,
  itemShape: strip.itemShape,
  itemSize: strip.itemSize,
  stripBackground: strip.stripBackground,
  isPreview: strip.isPreview,
  shouldSimulateMobile: strip.shouldSimulateMobile,
  headerFontFamily: strip.headerFontFamily,
  headerText: strip.headerText,
  headerFontColor: strip.headerFontColor,
  headerFontSize: strip.headerFontSize,
  headerFontWeight: strip.headerFontWeight,
  headerProvider: strip.headerProvider,
  headerLtr: strip.headerLtr,
  horizontalHeaderPadding: strip.horizontalHeaderPadding,
  paddingTop: strip.paddingTop,
  paddingBottom: strip.paddingBottom,
  bottomBorderWidth: strip.bottomBorderWidth,
  topBorderWidth: strip.topBorderWidth,
  bottomBorderColor: strip.bottomBorderColor,
  topBorderColor: strip.topBorderColor,
  thumbnailsStrokeColor: strip.thumbnailsStrokeColor
});

const mapDispatchToProps = {
  initAsyncStateProps,
  stripSeen,
  onItemClicked: itemClicked,
  changeCurrent,
  stripTouchStart,
  stripTouchEnd,
  stripAttrChange,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StripContainer);
