import React from 'react';

const ImageRingWrapper = ({ isMobileSmallRound, thumbnailTotalWidth, thumbnailTotalHeight, children, hasSeen, isLoading, shape, thumbnailsStrokeColor }) => {
  const circleOrSquare = shape === 'round' ? 'circle' : 'square';
  const shouldShowStroke = (thumbnailsStrokeColor !== 'none' || (isMobileSmallRound && (thumbnailsStrokeColor = '#ee2e3d')));
  const svgStyle = { 
    enableBackground: 'new -580 439 577.9 194',
    strokeLinecap: `${circleOrSquare === 'circle' ? 'round' : 'square'}`, 
    width: thumbnailTotalWidth, 
    height: thumbnailTotalHeight, 
    stroke: `${hasSeen ? 'rgba(0,0,0,0.25)' : thumbnailsStrokeColor}`
  }
  
  return (
    <div className={`ring-wrapper ${isLoading ? `ring-wrapper-loading-${circleOrSquare}` : ''}`} style={{width: thumbnailTotalWidth, height: thumbnailTotalHeight}}>
      {children}
      {shouldShowStroke && 
      <>
        {shape === 'round' ?
          <svg  xmlns="http://www.w3.org/2000/svg" className={`${hasSeen ? 'has-seen' : ''}`} style={svgStyle} xmlSpace="preserve">
            <circle cx={`${parseInt(thumbnailTotalHeight)/2}`} cy={`${parseInt(thumbnailTotalHeight)/2}`} r={`${parseInt(thumbnailTotalHeight)/2}`} />
          </svg>
        :
          <svg xmlns="http://www.w3.org/2000/svg" className={`${hasSeen ? 'has-seen' : ''}`} style={svgStyle} xmlSpace="preserve">
            <rect rx={shape === 'roundSquare' ? '10' : '3'} height={thumbnailTotalHeight} width={thumbnailTotalWidth} />
          </svg>}
      </>}
    </div>
  );
}

export default ImageRingWrapper;