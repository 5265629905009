import React from 'react';

class ItemText extends React.Component {
  constructor() {
    super();
    this.state = {
      textWasEllipsized: false
    }
  }

  ellipsizeTextBox(index) {
    const textElement = document.getElementsByClassName('text-item-new')[index];
    const wordArray = textElement.innerHTML.split(' ');
    let textWasChange = false

    while (textElement.scrollHeight > textElement.offsetHeight) {
      wordArray.pop();
      textElement.innerHTML = wordArray.join(' ') + '...';
      textWasChange = true;
    }
    
    this.setState({
      textWasEllipsized: textWasChange
    })
  }

  componentDidMount() {
    this.ellipsizeTextBox(this.props.index)
  }

  render() {
    const isRTL = text => /[\u0590-\u07FF\u200F\u202B\u202E\uFB1D-\uFDFD\uFE70-\uFEFC]/.test(text);

    const getTextColor = (itemStyle, hasSeen) => {
      if (itemStyle && itemStyle.bottom !== '0') {
        if (hasSeen) {
          return 'rgba(255, 255, 255, 0.8)'
        } else {
          return 'white'
        }
      } else if (itemStyle.bottom === '0') {
        const textColorIsWhite = (/^(white|#ffffff|rgb\(255,255,255\)|rgba\(255,255,255\))$/.test(itemStyle.itemTextColor));
        if (hasSeen) {
          return (textColorIsWhite ? 'white' : 'rgba(0, 0, 0, 0.25)')
        } else {
          return (textColorIsWhite ? 'white' : '#3d3d3d')
        }
      }
    }

    const { caption, hasSeen, textStyle } = this.props;
    textStyle.color = getTextColor(textStyle, hasSeen);

        return (
      <>
        <div className={`text-item-new ${isRTL(caption) ? 'rtl' : ''}`} style={textStyle}>
          {this.state.textWasEllipsized ? '' : caption}
        </div>
      </>
    )
  }
}

export default ItemText;