import { urlParams } from './queryString/queryString';
import { EVENTS_TYPES } from './events-types';
import config from '../config/config';

const EVENTS_URL = 'https://events.apester.com/event';
let sessionId = '';
let consumerId = '';

export function getBasicEventProps(event) {
  switch (event) {
    case EVENTS_TYPES.strip_seen:
    case EVENTS_TYPES.strip_unit_clicked:
    case EVENTS_TYPES.strip_loaded:
      return {
        eventCategory: 'publish',
        eventType: 'engagement',
        eventElement: 'action',
      };
    default:
      // This is for monitoring default parameters should never sent.
      return {
        eventCategory: 'publish',
        eventType: 'default',
      };
  }
}

export const setConsumerId = (id) => {
  consumerId = id;
}

export const setSessionId = (id) => {
  sessionId = id;
}

const getMetadata = () => {
    const metadata = {
    referrer: document.referrer,
    current_url: decodeURIComponent(urlParams.canonicalUrl),
    screenHeight: window.screen.height.toString(),
    screenWidth: window.screen.width.toString(),
    language: window.navigator.language,
  };
  if (sessionId) {
    metadata.sessionId = sessionId;
    metadata.consumerSessionId = sessionId;
  }

  if (consumerId) {
    metadata.consumerid = consumerId;
  }

return metadata;
}

export const trackEvent = (event, eventData) => {


  const properties = {
    release: config.RELEASE,
    canonicalUrl: decodeURIComponent(urlParams.canonicalUrl),
    ...eventData,
    ...getBasicEventProps(event)
  };
  const metadata = getMetadata();

  const options = {
    method: 'POST',
    body: JSON.stringify({
      event,
      properties,
      metadata
    }),
  };
  fetch(EVENTS_URL, options);
};
