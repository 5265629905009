import 'whatwg-fetch';

import React from 'react';
import { Provider } from 'react-redux';

import StripContainer from './StripContainer/stripContainer';
import { store } from '../store/store';
import {
  stripItemFetched, closeStrip, initAsyncStateProps, refreshContent,
} from '../store/actions/actions';
import config from '../config/config';
import { urlParams } from '../services/queryString/queryString';
import './app.css';
import { getPlatform, getOS } from '../services/device/deviceData';
import {setConsumerId} from '../services/EventsTracker';
const loadStripContent = async (token) => {
  try {
    const apeOriginalHeader = new Headers({ 'X-Ape-Origin': document.referrer });
    const response = await fetch(`${config.DISPLAY_BASE_URL}/strip?tokens=${token || urlParams.token}&platform=${getPlatform()}&os=${getOS()}${urlParams.isPreview ? '&preview=true' : ''}`, { headers: apeOriginalHeader });
    if (response.status < 200 || response.status > 299 ) {
      throw new Error('request error');
    }
    const { payload } = await response.json();
    if (urlParams.isPreview) {
      window.parent.postMessage({ type: 'DEFAULT_THUMBNAILS_STRIP', data: payload.defaultThumbnails }, '*');
    }
    store.dispatch(stripItemFetched(payload));
    store.dispatch(initAsyncStateProps(payload));
  } catch (e) {
    store.dispatch(closeStrip('request error'));

    console.log('error', e);
    // TODO: handle failure and close strip.
  }
};

class App extends React.Component {
  async componentDidMount() {
    loadStripContent();
    window.addEventListener('message', this.refreshContentListener);
    window.addEventListener('message', this.addConsumerId);

  }

  componentWillUnmount() {
    window.removeEventListener('message', this.refreshContentListener);
    window.removeEventListener('message', this.addConsumerId);

  }

  refreshContentListener = (event) => {
    if (event.data.type === 'apester_strip_refresh_content') {
      loadStripContent();
      store.dispatch(refreshContent(event.data.newSessionId));
    }
  };

  addConsumerId = (event) => {
    if (event.data.type === 'apester_consumerId') {
      const consumerId = event.data.consumerId;
      setConsumerId(consumerId);
    }
  }

  render = () => (
    <Provider store={store}>
      <StripContainer />
    </Provider>
  );
}

export default App;
