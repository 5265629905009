import React from 'react';
import ItemImage from '../ItemImage/ItemImage';
import ItemText from '../ItemText/ItemText';

import './strip-item.scss';

const getItemStyle = itemStyle => ({
  borderRadius: itemStyle && itemStyle.borderRadius,
});

const getTextStyle = itemStyle => ({
  marginTop: `${itemStyle.textMarginTop || '12px'}`,
  width: (itemStyle && itemStyle.textWidth) || '100%',
  fontSize: itemStyle && itemStyle.fontSize,
  fontWeight: 400,
  whiteSpace: itemStyle && itemStyle.whiteSpace,
  bottom:
    itemStyle && itemStyle.textBottomPosition > 0
      ? `${itemStyle && parseInt(itemStyle.textBottomPosition)}px`
      : '0',
  position: itemStyle && itemStyle.textBottomPosition < 0 ? 'relative' : 'absolute',
  overflow: 'hidden',
  lineHeight: `${itemStyle.fontSize + 4}px`,  /* fallback */
  maxHeight: `${(itemStyle.fontSize + 4) * 2}px`,  /* fallback */
  itemTextColor: itemStyle.itemTextColor
});

const getImageStyle = (itemStyle) => {
  const BLACK_OVERLAY = 'linear-gradient(to bottom, rgba(0,0,0,0) 66%, rgba(0,0,0,1))';

  return {
    width: itemStyle && itemStyle.width,
    height: itemStyle && itemStyle.height,
    borderRadius: itemStyle && itemStyle.borderRadius,
    background: itemStyle.textBottomPosition < 0 ? 'none' : BLACK_OVERLAY,
  };
};

const StripItem = ({ index, interactionId, hasSeen, image, caption, isMobile, onClick, itemStyle, itemSize, itemShape, isLoading }) => {
  let mouseX = 0;
  let delta = 0;
  return (
    <div
      className={isMobile ? 'story-item-new' : 'story-item-new story-item-desktop'}
      style={getItemStyle(itemStyle)}
      onClick={e => {
        if (delta <= 3) {
          onClick(index, interactionId);
        }
      }}
      onMouseDown={e => {
        mouseX = e.x;
      }}
      onMouseUp={e => {
        delta = Math.abs(mouseX - e.x);
        mouseX = 0;
      }}
    >
      <ItemImage itemSize={itemSize} itemShape={itemShape} thumbnailsStrokeColor={itemStyle.thumbnailsStrokeColor} isMobile={isMobile} isLoading={isLoading} image={image} avatarStyle={getImageStyle(itemStyle)} hasSeen={hasSeen} />
      <ItemText caption={caption} hasSeen={hasSeen} textStyle={getTextStyle(itemStyle)} index={index} />
    </div>
  );
};

export default StripItem;
