import { actionsBank } from '../actions/actionBank';
import { urlParams } from '../../services/queryString/queryString';

const initialState = {
  items: [],
  current: 0, // change the initial index to (-1) if you use GoToSlide logics
  seen: {}, // populated in ASYNC_STATE_READY action from asyncStatePropsMiddleware
  currentlyLoadedIndex: undefined,
  token: urlParams.token || '',
  channelId: urlParams.channelId || '',
  itemShape: urlParams.itemShape || 'square',
  itemSize: urlParams.itemSize || 'medium',
  stripBackground: urlParams.stripBackground || 'rgba(0,0,0,0)',
  itemTextColor: urlParams.itemTextColor || 'black',
  isPreview: urlParams.isPreview || false,
  shouldSimulateMobile: false,
  headerFontFamily: urlParams.headerFontFamily || 'Lato',
  headerFontSize: urlParams.headerFontSize || '22',
  headerFontWeight: urlParams.headerFontWeight || '400',
  headerFontColor: urlParams.headerFontColor || 'rgba(0,0,0,1)',
  headerLtr: urlParams.headerLtr || 'true',
  headerProvider: urlParams.headerProvider || 'system',
  headerText: decodeURIComponent(urlParams.headerText) || '',
  paddingTop: urlParams.paddingTop || '0',
  paddingBottom: urlParams.paddingBottom || '0',
  horizontalHeaderPadding: urlParams.horizontalHeaderPadding || '8px',
  bottomBorderWidth: urlParams.bottomBorderWidth || '0',
  topBorderWidth: urlParams.topBorderWidth || '0',
  bottomBorderColor: urlParams.bottomBorderColor || 'black',
  topBorderColor: urlParams.topBorderColor || 'black',
  thumbnailsStrokeColor: urlParams.thumbnailsStrokeColor || 'rgb(264,46,61)'
};

/**
 *
 * @param state
 * @param action
 */
const stripReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsBank.ASYNC_STATE_READY: {
      const { asyncStateProps } = action.payload;
      return {
        ...state,
        ...asyncStateProps,
      };
    }
    case actionsBank.ITEM_CLICKED: {
      const { interactionIndex } = action.payload;

      return {
        ...state,
        currentlyLoadedIndex: interactionIndex,
      };
    }

    case actionsBank.CHANGE_CURRENT: {
      const { interactionId, interactionIndex } = action.payload;

      return {
        ...state,
        current: interactionIndex,
        seen: { ...state.seen, [interactionId]: {} },
        currentlyLoadedIndex: undefined,
      };
    }

    case actionsBank.STRIP_ITEMS_FETCHED: {
      const { stripItems: items } = action.payload;
      const channelId = items && items[0] && items[0].publisherId;
      return { ...state, items, channelId };
    }
    case actionsBank.STRIP_ATTR_CHANGE: {
      const { attr } = action.payload;
      return {
        ...state,
        ...attr,
      };
    }
    default:
      return state;
  }
};

export default stripReducer;
