import { actionsBank } from './actionBank';

export const initAsyncStateProps = () => ({
  type: actionsBank.INIT_ASYNC_STATE_PROPS,
  payload: {},
});

export const itemClicked = (index, id) => ({
  type: actionsBank.ITEM_CLICKED,
  payload: {
    interactionIndex: index,
    interactionId: id,
  },
});

export const stripTouchStart = (index, id) => ({
  type: actionsBank.TOUCH_START,
  payload: {},
});
export const stripTouchEnd = (index, id) => ({
  type: actionsBank.TOUCH_END,
  payload: {},
});

export const changeCurrent = (index, id) => ({
  type: actionsBank.CHANGE_CURRENT,
  payload: {
    interactionIndex: index,
    interactionId: id,
  },
});

export const stripSeen = () => ({
  type: actionsBank.STRIP_SEEN,
  payload: {},
});

export const stripItemFetched = (payload) => ({
  type: actionsBank.STRIP_ITEMS_FETCHED,
  payload,
});

export const closeStrip = (reason) => ({
  type: actionsBank.CLOSE_STRIP,
  payload: {
    reason,
  },
});
export const stripAttrChange = (attr) => ({
  type: actionsBank.STRIP_ATTR_CHANGE,
  payload: {
    attr,
  },
});

export const refreshContent = (newSessionId) => ({
  type: actionsBank.STRIP_REFRESH_CONTENT,
  payload: {
    newSessionId,
  },
});
