const isWindowHasIntersectionObserver = () => ('IntersectionObserver' in window)
  && ('IntersectionObserverEntry' in window)
  && ('intersectionRatio' in window.IntersectionObserverEntry.prototype);

export const createOnviewObserver = (element, callback) => {
  if (!isWindowHasIntersectionObserver) {
    callback();
    return;
  }
  const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.05,
  };

  const observer = new IntersectionObserver((entries, currentObserver) => {
    const [entry] = entries; // We only observe one element.
    if (entry.isIntersecting) {
      callback();
      currentObserver.disconnect();
    }
  }, observerOptions);
  observer.observe(element);
};
