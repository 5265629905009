import { GTM_ACTIONS } from '../../store/actions/actionBank';

declare global {
  interface Window {
    dataLayer: Object[];
  }
}

let stripExtraData;

window.addEventListener('message', (event) => {
  if (event && event.data && event.data.type === 'strip-data') {
    stripExtraData = {
      ...(event.data.adUnit && {ad_unit: event.data.adUnit}),
      ...(event.data.partner && {partner: event.data.partner}),
    }
  }
})

export default class GtmEventHandler {
  static stripLoaded(payload): void {
    this.fireGtmEvent({
      type: GTM_ACTIONS.STRIP_LOADED,
      payload: {
        event: 'strip_loaded',
        ...payload,
      }
    })
  }

  static stripUnitClicked(payload): void {
    this.fireGtmEvent({
      type: GTM_ACTIONS.STRIP_UNIT_CLICKED,
      payload: {
        event: 'strip_unit_clicked',
        ...payload,
      }
    })
  }

  static stripSeen(payload): void {
    this.fireGtmEvent({
      type: GTM_ACTIONS.STRIP_SEEN,
      payload: {
        event: 'strip_seen',
        ...payload,
      }
    })
  }

  static fireGtmEvent({type, payload}) {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      event: type,
      ...payload,
      ...stripExtraData
    });
  }
}
