import { actionsBank } from '../actions/actionBank';
import {trackEvent, setSessionId} from '../../services/EventsTracker';
import GtmEventHandler from '../../services/gtm-event-handler/gtm-event-handler';

import { urlParams } from '../../services/queryString/queryString';
import { EVENTS_TYPES } from '../../services/events-types';

setSessionId(urlParams.sessionId);

const postMessageMiddleware = store => next => action => {
  switch (action.type) {
    case actionsBank.ITEM_CLICKED: {
      const { interactionIndex, interactionId } = action.payload;
      const channelId = store.getState().strip.channelId;
      trackEvent(EVENTS_TYPES.strip_unit_clicked, { interactionId, interactionIndex: interactionIndex.toString(), publisherId: channelId });
      GtmEventHandler.stripUnitClicked({ channel_id: channelId, interaction_index: interactionIndex });
      break;
    }
    case actionsBank.ASYNC_STATE_READY: {
      if (urlParams.isPreview) {
        break;
      }
      const currentStripState = store.getState().strip;
      const { channelId, items } = currentStripState;
      if (items && items.length > 0) {
        const { campaignData } = items[0];
        const videoCampaignId = campaignData?.videoCampaignOptions?.videoCampaignId;
        const campaignId = campaignData?.companionCampaignOptions?.companionCampaignId;
        trackEvent(EVENTS_TYPES.strip_loaded, { publisherId: channelId, videoCampaignId, campaignId });
        GtmEventHandler.stripLoaded({ channel_id: channelId, num_of_items: items.length });
      }
      break;
    }
    case actionsBank.STRIP_SEEN: {
      if (urlParams.isPreview) {
        break;
      }
      const currentStripState = store.getState().strip;
      const { channelId, items } = currentStripState;
      trackEvent(EVENTS_TYPES.strip_seen, { publisherId: channelId });
      GtmEventHandler.stripSeen({ channel_id: channelId, num_of_items: items.length });
      break;
    }
    case actionsBank.STRIP_REFRESH_CONTENT:
      const { newSessionId } = action.payload;
      setSessionId(newSessionId);
      break;
    default:
  }
  const result = next(action);
  return result;
};

export default postMessageMiddleware;
