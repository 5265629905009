import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducers/rootReducer';

import asyncStatePropsMiddleware from './middlewares/asyncStatePropsMiddleware';
import postMessageMiddleware from './middlewares/postMessageMiddleware';
import eventsTrackerMiddleware from './middlewares/eventsTrackerMiddleware';

const initialState = {};

export const store = createStore(rootReducer, initialState, applyMiddleware(asyncStatePropsMiddleware, postMessageMiddleware, eventsTrackerMiddleware));
export default store;
