export const getHeight = ( isMobile, headerTextExists, hidePoweredBy, paddingTop, paddingBottom, bottomBorderWidth, topBorderWidth ) => {
  const defaultSize = isMobile ? 130 : 145;
  const thumbnailHeight = document.getElementsByClassName('glider-track') && document.getElementsByClassName('glider-track')[0] && document.getElementsByClassName('glider-track')[0].offsetHeight;

  const stripHeight = (parseInt(thumbnailHeight) || defaultSize) + parseInt(paddingTop) + parseInt(paddingBottom) + parseInt(bottomBorderWidth) + parseInt(topBorderWidth);
  //if header text exist - calculate header based on it's height
  if (headerTextExists) {
    let headerHeight = (document.getElementsByClassName('strip-header') && document.getElementsByClassName('strip-header')[0] && document.getElementsByClassName('strip-header')[0].offsetHeight) || 0;
    return stripHeight + headerHeight;
  }

  //if header text not exist - calculate header based on if powered by exist or not
  if (!hidePoweredBy) {
    const POWERED_BY_HEIGHT = isMobile ? 22 : 34;
    return isMobile ? stripHeight + POWERED_BY_HEIGHT : stripHeight + POWERED_BY_HEIGHT;
  }
  
  return stripHeight;
};

export const shouldHidePoweredBy = items => {
  return items && items[0] && items[0].publisher && items[0].publisher.poweredBy && !items[0].publisher.poweredBy.active;
};
